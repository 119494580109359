.loading-body {
    height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.loading-container {
    position: relative;
}

.logo {
    position: relative;
    z-index: 2;
    width: 100px;
    height: 100px;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    animation: bounce 1s infinite;
}

.wave {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid #7127FF;
    opacity: 0;
    animation: waveSpread 1s infinite;
    z-index: 1;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

@keyframes waveSpread {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        transform: translate(-50%, -50%) scale(3);
        opacity: 0;
    }
}